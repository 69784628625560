var cookieFactory = {
    setCookie: function(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },

    getCookie: function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) ==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },

    removeCookie: function(name) {
        document.cookie = name+'=; Max-Age=-99999999;';
    }
};

function getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

function getViewport(){
    var currentWidth;
    var xs = document.querySelector(".media-size-xs"),
    sm = document.querySelector(".media-size-sm"),
    md = document.querySelector(".media-size-md"),
    lg = document.querySelector(".media-size-lg"),
    xl = document.querySelector(".media-size-xl");

    if (xs.offsetWidth > 0 || xs.offsetHeight > 0) {
        currentWidth = "xs";
    }
    else if (sm.offsetWidth > 0 || sm.offsetHeight > 0) {
        currentWidth = "sm";
    }
    else if (md.offsetWidth > 0 || md.offsetHeight > 0) {
        currentWidth = "md";
    }
    else if (lg.offsetWidth > 0 || lg.offsetHeight > 0) {
        currentWidth = "lg";
    }
    else if (xl.offsetWidth > 0 || xl.offsetHeight > 0) {
        currentWidth = "xl";
    }
    return currentWidth;
};

function maxHeightBySelector(selector) {
    return Math.max.apply(null,
        [].map.call(
            document.querySelectorAll(selector),
            el => el.clientHeight
        )
    );
}

jQuery(document).ready(function($) {
    $(function(){

        $.fn.extend({
            slickDefault: function(options, cb, cbFirst){
                let $slickEl;
                let defaults = {
                    dots: false,
                    infinite: true,
                    speed: 700,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    fade: true,
                    arrows: true
                };

                let mergedOptions = {...defaults, ...options };

                if (cb && typeof cb == "function" && cbFirst) {
                    cb($(this));
                    $slickEl = $(this).slick(mergedOptions);
                } else {
                    $slickEl = $(this).slick(mergedOptions);

                    if (cb && typeof cb == "function") {
                        cb($slickEl);
                    }
                }

                return $slickEl;
            },
            multiPanel: function(cb) {
                $(this).on("click", function(e){
                    var clicked  = $(this);
                    var target   = $(clicked.data("multipanel-content-target"));
                    var active   = target.closest(".multipanel-content").find(".active");
                    var triggers = clicked.closest(".multipanel-nav").find("[data-multipanel-content-target]");

                    var multipanel = target.closest(".multipanel");
                    var sectionContainer = multipanel.closest(".section-container");
                    var isFullWidthPanel = clicked.data("full-width");

                    if (target.is(active)) {
                        return false;
                    } else {
                        triggers.removeClass("active");
                        active.removeClass("active");
                        clicked.addClass("active");
                        target.addClass("active");
                    }

                    if (isFullWidthPanel) {
                        sectionContainer.removeClass("section-container--medium");
                        sectionContainer.addClass("section-container--full-width");
                    } else {
                        sectionContainer.removeClass("section-container--full-width");
                        sectionContainer.addClass("section-container--medium");
                    }

                    if (cb && typeof cb == "function") {
                        return cb(clicked);
                    }
                });
            }
        });

        // ======================
        // SLIDERS

        // Hero Sliders

        var heroSlider = $("#hero-slider");

        if (heroSlider.length) {
            heroSlider.slick({
                dots: true,
                arrows: false,
                infinite: true,
                speed: 700,
                autoplay: true,
                autoplaySpeed: 4000
            });
        }


        // Multipanel Sliders

        $("#floorplans-nav .multipanel-nav-menu-item").multiPanel(initFloorPlanSlider);
        $("#floorplans-nav .multipanel-nav-menu-item:first").trigger("click");

        $("#galleries-nav .multipanel-nav-menu-item").multiPanel(initGalleriesSlider);
        $("#galleries-nav .multipanel-nav-menu-item:first").trigger("click");

        function initFloorPlanSlider(clickedEl) {
            var target  = $(clickedEl.data("multipanel-content-target"));
            var isSlick = clickedEl.data("init-slick");
            var active  = target.closest(".multipanel-content").find(".multipanel-content-item.active");

            if (target.is(active)) return false;

            if (isSlick) {
                $(".multipanel").addClass("multipanel--use-sidebar");

                if (!target.hasClass("slick-initialized")) {
                    $(".multipanel-item.slick-initialized").slick("unslick");
                    target.slickDefault({}, slickCallback, true);
                }
            } else {
                $(".multipanel").removeClass("multipanel--use-sidebar");
                $(".multipanel-item.slick-initialized").slick("unslick");
            }

            function slickCallback($slickInstance) {
                $slickInstance.on('init afterChange', function(event, slick, currentSlide, nextSlide) {
                    // Update Sidebar Specs
                    var currentSlide = $(slick.$slides.get(currentSlide));
                    var dataObj = currentSlide.find("img").data("multipanel-item-specs");
                    var specs = dataObj.specs;
                    var specsWrapper = $(".multipanel-sidebar__specs");

                    var specHtml = `
                        <li class="multipanel-sidebar__spec">
                            <span class='text-transform-capitalize'>Bedrooms:</span>
                            <span id="multipanel-spec-bedrooms">${dataObj['bedrooms']}</span>
                        </li>`;

                    for (let spec in specs) {
                        specHtml += `
                            <li class="multipanel-sidebar__spec">
                                <span class='text-transform-capitalize'>${spec}:</span>
                                <span id="multipanel-spec-${spec}">${specs[spec]}</span>
                            </li>`;
                    }

                    $(".multipanel-sidebar__label").html(dataObj.planId);


                    target.addClass("active");

                    specsWrapper.html(specHtml);

                    // Toggle Active Sub-Menu Group
                    $(".multipanel-nav-sub-menu li").removeClass("active");
                    $("[data-plan-id-target='" + dataObj.planId + "']").addClass("active");
                });

                // Go to 1st Slide of Active Sub-Menu Group
                $(".multipanel-nav-sub-menu li").on("click", function(e) {
                    var planId = $(this).data("plan-id-target");
                    var planIndex = $(this).data("plan-id-index");

                    $(".multipanel-nav-sub-menu li").removeClass("active");
                    $(this).addClass("active");
                    $(".multipanel-item.slick-initialized").slick('slickGoTo', planIndex);
                });
            }
        }

        function initGalleriesSlider(clickedEl) {
            var target  = $(clickedEl.data("multipanel-content-target"));
            var isSlick = clickedEl.data("init-slick");
            var active  = target.closest(".multipanel-content").find(".multipanel-content-item.active");

            if (target.is(active)) return false;

            if (isSlick) {
                if (!target.hasClass("slick-initialized")) {
                    $(".multipanel-item.slick-initialized").slick("unslick");
                    target.slickDefault({});
                }
            } else {
                $(".multipanel-item.slick-initialized").slick("unslick");
            }
        }


        // ======================
        // Navigation
        (function navigation(){
            var winWidth = $(window).width();
            var hamburger = $(".hamburger");
            var mainHeader = $(".main-header");
            var mainHeaderNav = $(".main-header__nav");
            var menuItemsWithChildren = $(".menu-item-has-children");
            var subMenus = document.getElementsByClassName("sub-menu");

            // show mobile nav

            hamburger.click(function() {
                $(this).toggleClass("is-active");

                if ($(this).hasClass("is-active")) {
                    mainHeader.addClass("mobile-nav-expanded");
                    mainHeaderNav.addClass("mobile-nav-expanded");
                } else {
                    mainHeader.removeClass("mobile-nav-expanded");
                    mainHeaderNav.removeClass("mobile-nav-expanded");
                }
            });

            menuItemsWithChildren.children("a").removeAttr("href");

            // show sub-menu

            menuItemsWithChildren.on('click', function(e) {
                var clickedLi = $(this);
                var clickedLiSubMenu = clickedLi.find(".sub-menu");

                var clickedLiSubMenuIsOpen = clickedLi.hasClass("sub-menu--open");
                var clickTargetIsSubMenuLink = e.target.href.length;

                /* Hide all sub-menus */

                menuItemsWithChildren.removeClass("sub-menu--open");
                menuItemsWithChildren.each(function(ind, el) {
                    $(el).find(".sub-menu").removeClass("sub-menu--open");
                });

                /* Toggle "sub-menu--open" class of click target */

                if (clickedLiSubMenuIsOpen && !clickTargetIsSubMenuLink) {
                    clickedLi.removeClass("sub-menu--open");
                    clickedLiSubMenu.removeClass("sub-menu--open");
                } else {
                    clickedLi.addClass("sub-menu--open");
                    clickedLiSubMenu.addClass("sub-menu--open");
                }

            });

            // Resize Events

            $(window).resize(function() {
                if ($(window).width() != winWidth && ((winWidth - $(window).width() > 10) || (winWidth - $(window).width() < -10))) {
                    winWidth = $(window).width();
                    mainHeader.removeClass("mobile-nav-expanded");
                    hamburger.removeClass("is-active");
                    mainHeaderNav.removeClass("mobile-nav-expanded");
                }
            });

            // Show Main Nav if page is loaded with initial scrollY > 0

            if (window.scrollY > 0) {
                mainHeader.addClass("nav-down")
            }

        }());

        // ======================
        // Hide header on down scroll
        (function scrollHideHeader() {
            var didScroll;
            var lastScrollTop = 0;
            var delta = 5;
            var mainHeader = $(".main-header");
            var mainHeaderHeight = mainHeader.outerHeight();

            $(window).scroll(function(event){
                didScroll = true;
            });

            setInterval(function() {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 250);

            function hasScrolled() {
                // window.pageYOffset
                var st = $(window).scrollTop();

                // Make sure they scroll more than delta
                if(Math.abs(lastScrollTop - st) <= delta)
                return;

                // If they scrolled down and are past the navbar, add class .nav-up.
                // This is necessary so you never see what is "behind" the navbar.
                if (st > lastScrollTop && st > mainHeaderHeight){
                    // Scroll Down
                    mainHeader.removeClass('nav-down').addClass('nav-up');
                } else {
                    // Scroll Up

                    if(st + $(window).height() < $(document).height()) {
                        mainHeader.removeClass('nav-up').addClass('nav-down');
                    }

                    if (st < mainHeaderHeight){
                        mainHeader.removeClass('nav-up').removeClass('nav-down');
                    }
                }

                lastScrollTop = st;
            }

        }());

        // ======================
        // Smooth anchor scroll
        $(".smoothScroll").on("click", function (e) {
            e.preventDefault();
            var targetId = $(this).attr("href");

            $("html, body").animate({
                scrollTop: $(targetId).offset().top
            }, 750);
        });
    });

    // Animate elements on scroll into view
    var animateHTML = function() {
        var elems;
        var windowHeight;

        function init() {
            elems = document.querySelectorAll('.animate-element');
            windowHeight = window.innerHeight;
            addEventHandlers();
            checkPosition();
        }

        function addEventHandlers() {
            window.addEventListener('scroll', checkPosition);
            window.addEventListener('resize', init);
        }

        function checkPosition() {
            for (var i = 0; i < elems.length; i++) {
                var positionFromTop = elems[i].getBoundingClientRect().top;
                var elemHeight      = elems[i].offsetHeight;
                var windowScrollTop = window.scrollY;

                if (positionFromTop - windowHeight + 60 <= 0) {
                    elems[i].className = elems[i].className.replace(
                        'animate-element--hidden',
                        'animate-element--active'
                    );
                } else if (positionFromTop - windowHeight > 0 || windowScrollTop > positionFromTop + elemHeight + windowHeight) {
                    elems[i].className = elems[i].className.replace(
                        'animate-element--active',
                        'animate-element--hidden'
                    );
                }
            }
        }

        return {
            init: init
        };
    };

    animateHTML().init();
});
